import { useMutation, useQuery } from '@tanstack/react-query'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import * as Yup from 'yup'

import { Button, InputField, PanelHeader, RightPanel } from '~components'
import { PaymentMethod } from '~types'
import useApi from '~api/api'

type ExportForm = {
  locale: string
  reportType: 'aggregate' | 'detailed' | 'ticketype'
  exportTimeframe: 'today' | 'thisMonth' | 'lastMonth'
  columns: { [key: string]: string | boolean }
}

export const ExportPage = () => {
  const { t, i18n } = useTranslation()
  const api = useApi()

  const paymentMethods = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: async () => {
      const response = await api.get<PaymentMethod[]>(
        '/payments/methods/',
        {},
        { signal: null, timeout: 180000 },
      )
      return response.data
    },
  })

  const reportExport = useMutation({
    mutationFn: async (values: ExportForm) => {
      const response = await api.post<string>(`/stats/export/${values.reportType}/`, values, {
        signal: null,
        timeout: 180000,
      })
      return response.data
    },
  })

  if (!paymentMethods || paymentMethods.isLoading) {
    return null
  }

  const initialValues: ExportForm = {
    locale: i18n.language,
    exportTimeframe: 'today',
    reportType: 'aggregate',
    columns: {
      dayName: true,
      txnDate: true,
      pax: true,
      totalPax: true,
      totalSales: true,
    },
  }

  const columns = [
    {
      name: t('ExportPage.day'),
      description: t('ExportPage.day_desc'),
      field: 'dayName',
      selectable: false,
    },
    {
      name: t('ExportPage.date'),
      description: t('ExportPage.date_desc'),
      field: 'txnDate',
      selectable: false,
    },
    {
      name: t('ExportPage.pax'),
      description: t('ExportPage.pax_desc'),
      field: 'pax',
      selectable: true,
    },
    {
      name: t('ExportPage.totalPax'),
      description: t('ExportPage.totalPax_desc'),
      field: 'totalPax',
      selectable: false,
    },
  ]
  paymentMethods.data?.forEach((method: PaymentMethod) => {
    columns.push({
      name: method.displayName || method.method.name,
      description: '',
      field: method.method.id,
      selectable: true,
    })
    initialValues.columns[method.method.id] = true
  })
  columns.push({
    name: t('ExportPage.totalSales'),
    description: t('ExportPage.totalSales_desc'),
    field: 'totalSales',
    selectable: false,
  })

  return (
    <RightPanel>
      <PanelHeader
        returnsTo="/"
        title={t('ExportPage.title', 'Report Export')}
        subtitle={<p>{t('ExportPage.subtitle')}</p>}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          exportTimeframe: Yup.string().required(t('ValidationMessages.required', 'Is required')),
        })}
        onSubmit={(values) => {
          reportExport.mutate(values, {
            onSuccess: (data: string) => {
              const url = window.URL.createObjectURL(new Blob([data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
                'download',
                `report_${values.exportTimeframe}_${moment.utc().format('YYYY_MM_DD')}.csv`,
              )
              document.body.appendChild(link)
              link.click()
            },
          })
        }}>
        {({ submitForm, values }) => {
          return (
            <>
              <div>{t('ExportPage.select_report_type', 'Select Report Type')}</div>
              <div className="grid grid-cols-2 gap-4 mb-8">
                <InputField
                  name="reportType"
                  type="radio"
                  label={t('ExportPage.report_type', 'Report Type')}
                  selectValues={[
                    { id: 'aggregate', name: t('ExportPage.aggregate', 'Aggregate') },
                    { id: 'detailed', name: t('ExportPage.detailed', 'Detailed') },
                  ]}
                  className="py-2"
                />
              </div>
              {values.reportType === 'aggregate' && (
                <>
                  <div>{t('ExportPage.select_columns_to_export', 'Select Columns to Export')}</div>
                  <div className="grid grid-cols-2 gap-4 mb-8">
                    {columns.map((column) => (
                      <InputField
                        key={column.field}
                        name={`columns.${column.field}`}
                        type="checkbox"
                        label={column.name}
                        description={column.description}
                        disabled={!column.selectable}
                      />
                    ))}
                  </div>
                </>
              )}
              <div>{t('ExportPage.select_export_timeframe', 'Select Export Timeframe')}</div>
              <InputField
                type="radio"
                name="exportTimeframe"
                label={t('ExportPage.exportTimeframe', 'Export Timeframe')}
                selectValues={[
                  { id: 'today', name: t('ExportPage.today', 'Today') },
                  { id: 'thisMonth', name: t('ExportPage.this_month', 'This Month') },
                  { id: 'lastMonth', name: t('ExportPage.last_month', 'Last Month') },
                ]}
                className="py-2"
              />
              <Button onClick={submitForm}>{t('ExportPage.export', 'Export')}</Button>
            </>
          )
        }}
      </Formik>
    </RightPanel>
  )
}
